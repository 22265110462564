import React  from 'react';
import logo from '../../logo.png';
import YesIcon from '../../yes.svg';
import './index.css';

function SaaS (props) {
  return (
    <div className="App">
      { props.mode === 'waiting' &&
          <header className='App-header'>
            {props.waitingMessage}
          </header>
      }
      { props.mode === 'error' && 
          <header className="App-header">
            <img src='https://media.giphy.com/media/3ohzdYt5HYinIx13ji/giphy.gif'/>
            {/* <div className='separator-line'>
              <div className='separator-line-before'></div>
              <div className='separator-line-middle'>ACCESS TO <b>WNC</b> BANK IS DENIED</div>
              <div className='separator-line-after'></div>
            </div> */}
          </header>
      }
      { props.mode === 'payment' &&
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Welcome to <b>WNC Bank</b>, <span className="User-Name">{props.user.first_name}</span>
          </p>
          <div className="Payment">
            <div className="App-Text Title">{props.payment.title}</div>
            <div className="App-Text Price">Price: {props.payment.amount} {props.payment.currency}</div>
            <button onClick={() => props.pay('paypal')}>Pay with PayPal</button>
            <button onClick={() => props.pay('cryptocurrency')}>Pay with Cryptocurrency</button>
          </div>
          <div className="Sub-Text">Here you can pay for the selected option/feature. After payment, you will be redirected to the page with the program. If you have any questions, please contact us via chat</div>
        </header>
      }
      { props.mode === 'subscription' &&
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Welcome to <b>WNC Bank</b>, <span className="User-Name">{props.user.first_name}</span>
            </p>
            { 
                !props.subscription ? 
                  <div className="Sub-Text">Please choose the best tariff plan for you. If you have any questions, please contact us via chat</div>
                  :
                  <div className="Sub-Text">In case of cancellation/updating of the subscription, the money will not be returned. To return them, please contact us by the chat</div>
            }
            <div className='Card-List'>
              { 
                !props.subscription ? 
                  props.plans[props.getLanguage()][props.app].map((plan) => {
                    return <div className="Plan-Card">
                      <div className="Plan-Info">
                        <div className="App-Text Title">{props.local === null ? plan.title : ''}</div>
                        <div className="App-Text Details">{props.local === null ? plan.details.map((option) => <div className="App-Text"><img className="Yes-Icon" src={YesIcon}/>{option}</div>) : ''}</div>
                      </div>
                      <button onClick={() => props.createSubscription(plan.ref)}>Select {props.local === null ? plan.currency : ''}{props.local === null ? plan.price : ''}</button>
                    </div>
                  })
                  : ''
              }
              { !props.subscription ? 
                <div className="Plan-Card">
                  <div className="Plan-Info">
                    <div className="App-Text Title">Special Offer</div>
                    <div className="App-Text Details">
                      <div className="App-Text"><img className="Yes-Icon" src={YesIcon}/>Do you need more?</div>
                      <div className="App-Text"><img className="Yes-Icon" src={YesIcon}/>Do you have something to offer?</div>
                      <div className="App-Text"><img className="Yes-Icon" src={YesIcon}/>Got an idea?</div>
                      <div className="App-Text"><img className="Yes-Icon" src={YesIcon}/>Have a question?</div>
                    </div>
                  </div>
                  <button onClick={() => props.contactUs()}>Contact Us</button>
                </div>
                :
                <div className="Plan-Card">
                  <div className="Plan-Info">
                    <div className="App-Text Title">{props.plans[props.getLanguage()][props.app].filter(p => p.ref === props.subscription.plan_id)[0].title}</div>
                    <div className="App-Text Details">{props.local === null ? props.plans[props.getLanguage()][props.app].filter(p => p.ref === props.subscription.plan_id)[0].details.map((option) => <div className="App-Text"><img className="Yes-Icon" src={YesIcon}/>{option}</div>) : ''}</div>
                    <div className="App-Text Details">Status: {props.subscription.status}</div>
                  </div>
                  { props.subscription.status !== 'APPROVAL_PENDING' ? 
                    <div className='Plan-Actions'>
                      {/* <button onClick={() => updateSubscription()}>Update</button> */}
                      <button onClick={() => props.cancelSubscription()}>Cancel</button>
                    </div> 
                    :
                    <div className='Plan-Actions'>
                      <button onClick={() => props.approveSubscription()}>Approve</button>
                      <button onClick={() => props.cancelSubscription()}>Cancel</button>
                    </div>
                  }
                </div>
              }
            </div>
          </header>
      }    
    </div>
  )
}

export default SaaS;
