import React  from 'react';
import payments from './payments.json';
import './App.css';
import Ecommerce from './Components/Ecommerce';
import SaaS from './Components/SaaS';

class App extends React.Component  {
  constructor(props) {
    super(props);

    const query = new URLSearchParams(window.location.search);

    // Don't call this.setState() here!
    this.state = { 
      loadPage: true,
      mode: null,
      subscription: null,
      payment: null,
      user: null,
      waitingMessage: null,
      app: query.get('app'),
      type: this.getType(query.get('app')),
      token: query.get('token'),
      product_id: query.get('payment'),
      local: query.get('local')
    };

    this.createSubscription = this.createSubscription.bind(this);
    this.pay = this.pay.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.approveSubscription = this.approveSubscription.bind(this);
    this.updateSubscription = this.updateSubscription.bind(this);
  }

  updateState = (newState) => {
    return this.setState(prevState => ({...prevState, ...newState}))
  }

  async componentDidMount() {
    await this.loadUser();

    if (this.state.product_id) {
      const selected_payment = payments[this.state.product_id]
      if (selected_payment) {
        this.updateState({payment: selected_payment, mode: 'payment'})
      }
    } else {
      await this.loadSubscription();
    }
    
    this.updateState({loadPage: false});
  }

  getType = (app) => {
    if (app === 'checkinshop') {
      return 'ecommerce'
    } else {
      return 'saas'
    }
  }

  loadUser = async () => {
    try {
      const req_result = await fetch(`https://zx8hsmle2j.execute-api.eu-central-1.amazonaws.com/production/api/v1/user/profile?token=${this.state.token}`)
      const result = await req_result.json()
      
      if (result) {
        if (typeof result === 'string' || !result.success) {
          this.updateState({mode: 'error'})
        } else {
          this.updateState({user: result.data})
        }
      } else {
        this.updateState({mode: 'error'})
      }
    } catch(e) {
      this.updateState({mode: 'error'})
    }
  }

  loadSubscription = async () => {
    try {
      const req_result = await fetch(`https://t8zax8nfj1.execute-api.eu-central-1.amazonaws.com/production/api/v1/subscriptions/${this.state.app}/app?token=${this.state.token}`)
      const result = await req_result.json()
      if (result) {
        if (result.success) {
          this.updateState({mode: 'subscription', subscription: result.data})
        }
      }
    } catch(e) {
      this.updateState({mode: 'error'})
    }
  }

  createSubscription = async (plan_id) => {
    const req_result = await fetch(`https://t8zax8nfj1.execute-api.eu-central-1.amazonaws.com/production/api/v1/subscriptions?token=${this.state.token}`, {
      method: "POST",
      body: JSON.stringify({
        plan_id: plan_id,
        app: this.app
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })

    const result = await req_result.json()

    if (result.success) {
      window.open(result.data)
      this.updateState({mode: 'waiting', waitingMessage: 'Reload the page after payment'})
    } else {
      this.updateState({mode: 'error'})
    }
  }

  cancelSubscription = async () => {
    fetch(`https://t8zax8nfj1.execute-api.eu-central-1.amazonaws.com/production/api/v1/subscriptions/${this.state.app}/app?token=${this.state.token}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      }
    }).then(async () => {
      window.location.reload()
    })
  }

  getLanguage = () => {
    if (this.state.local === 'UA') { return 'UA' }
    else if (this.state.local === 'EN') { return 'EN' }
    else { return 'EN' }
  }

  contactUs = () => {
    window.location.href = "mailto:daniel@welcomenocode.com?subject=Offer New WNC Plan&body=message%20goes%20here";
  }

  pay = async (type) => {
    if (type === 'paypal') {
      const req_result = await fetch(`https://t8zax8nfj1.execute-api.eu-central-1.amazonaws.com/production/api/v1/payments?token=${this.state.token}`, {
        method: "POST",
        body: JSON.stringify({
          product_id: this.state.product_id,
          app: this.state.app
        }),
        headers: {
          "Content-Type": "application/json"
        }
      })

      const result = await req_result.json()

      if (result.success) {
        window.location.href = result.data
        this.updateState({mode: 'waiting', waitingMessage: 'Reload the page after payment'})
      } else {
        this.updateState({mode: 'error'})
      }
    } else { // crypto
      const req_result = await fetch(`https://t8zax8nfj1.execute-api.eu-central-1.amazonaws.com/production/api/v1/payments/crypto?token=${this.state.token}`, {
        method: "POST",
        body: JSON.stringify({
          product_id: this.state.product_id,
          app: this.state.app
        }),
        headers: {
          "Content-Type": "application/json"
        }
      })

      const result = await req_result.json()

      if (result.success) {
        window.location.href = result.data
        this.updateState({mode: 'waiting', waitingMessage: 'Reload the page after payment'})
      } else {
        this.updateState({mode: 'error'})
      }
    }
    
  }

  updateSubscription = () => {

  }
  
  approveSubscription = () => {
    window.open(this.state.subscription.approve_link)
  }

  render() {
    return (
      <>
        { !this.state.loadPage ?
            this.state.type === 'saas' ?
              <SaaS
                loadPage={this.state.loadPage}
                mode={this.state.mode}
                local={this.state.local}
                user={this.state.user}
                subscription={this.state.subscription}
                getLanguage={this.getLanguage}
                cancelSubscription={this.cancelSubscription}
                pay={this.pay}
                waitingMessage={this.state.waitingMessage}
                payment={this.state.payment}
                contactUs={this.contactUs}
                createSubscription={this.createSubscription}
                approveSubscription={this.approveSubscription}
              />  
              :
              <Ecommerce
              loadPage={this.state.loadPage}
              mode={this.state.mode}
              local={this.state.local}
              user={this.state.user}
              subscription={this.state.subscription}
              getLanguage={this.getLanguage}
              cancelSubscription={this.cancelSubscription}
              pay={this.pay}
              waitingMessage={this.state.waitingMessage}
              payment={this.state.payment}
              contactUs={this.contactUs}
              createSubscription={this.createSubscription}
              approveSubscription={this.approveSubscription}
            />
          : "Loading.." 
        }
      </>
    )
  }
}

export default App;
